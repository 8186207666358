<template>
    <div class="template">
        <slot
            v-if="!!$slots.hero"
            name="hero"
        />

        <main id="main-content-landmark">
            <slot />
        </main>
    </div>
</template>


<script setup>
const props = defineProps({
    theme: {
        type: String,
        required: false,
        default: 'theme-blue-brown',
    },
    themeVariant: {
        type: String,
        required: false,
        default: 'theme-light',
    }
});

const { theme, themeVariant } = toRefs(props);

useHead({
    bodyAttrs: {
        class: [theme.value, themeVariant.value]
    }
});
</script>
