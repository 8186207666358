import revive_payload_client_y6Ad4WEYaT from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_less@4.2.0__uuu6dp6o2272zxdqewclsqmjwi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eEZUVDpxwq from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_less@4.2.0__uuu6dp6o2272zxdqewclsqmjwi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_73fcjdRh0P from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_less@4.2.0__uuu6dp6o2272zxdqewclsqmjwi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_APlxxTHHfh from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_less@4.2.0__uuu6dp6o2272zxdqewclsqmjwi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_rp4wjYQWKC from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_less@4.2.0__uuu6dp6o2272zxdqewclsqmjwi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_qaBRuB9qaa from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_less@4.2.0__uuu6dp6o2272zxdqewclsqmjwi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8dwbqS1Wda from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_less@4.2.0__uuu6dp6o2272zxdqewclsqmjwi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_OS0efCVkS0 from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_less@4.2.0__uuu6dp6o2272zxdqewclsqmjwi/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_PX6H6nh8yg from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typescript@5.6.2_vue@3.5.4_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/allard-pierson/allard-pierson-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_ozkmP0Muns from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_less@4.2.0__uuu6dp6o2272zxdqewclsqmjwi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_DTtxAwdIZS from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.2_vue@3.5.4_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_jmiO7opjyA from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.2_vue@3.5.4_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_EfypsJi7vG from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.9.0_magicast@0.3.5_nuxt@3.13.1_@parcel+watche_ktiyxoykllf5xsawfto75zpsvy/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_8sB9voRFxz from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import nuxt_plugin_32B1TYVd89 from "/builds/allard-pierson/allard-pierson-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import gtm_cXsUZobmSF from "/builds/allard-pierson/allard-pierson-frontend/plugins/gtm.ts";
export default [
  revive_payload_client_y6Ad4WEYaT,
  unhead_eEZUVDpxwq,
  router_73fcjdRh0P,
  payload_client_APlxxTHHfh,
  navigation_repaint_client_rp4wjYQWKC,
  check_outdated_build_client_qaBRuB9qaa,
  chunk_reload_client_8dwbqS1Wda,
  cross_origin_prefetch_client_OS0efCVkS0,
  plugin_vue3_PX6H6nh8yg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ozkmP0Muns,
  switch_locale_path_ssr_DTtxAwdIZS,
  i18n_jmiO7opjyA,
  plugin_EfypsJi7vG,
  plugin_8sB9voRFxz,
  nuxt_plugin_32B1TYVd89,
  gtm_cXsUZobmSF
]